import React, { useEffect } from "react";
import { BrowserRouter as Router, Routes, Route, useLocation } from "react-router-dom";
import NotFound from "./Pages/NotFound";
import Login from "./Pages/Users/Login";
import UserHeader from "./components/Users/header/Header";
import "./styles/index.css";
import CreateAccount from "./Pages/Users/CreateAccount";
import HomeNavbar from "./components/Users/navbar/HomeNav";
import Home from "./Pages/Users/Home";
import UserLoginOtp from "./components/Users/Ui/UserLoginOtp";
import MainHome from "./Pages/Home";
import HomeNav from "./components/Users/navbar/HomeNav";
import TermsAndConditions from "./Pages/Legals/TermsAndConditions";
import PrivacyPolicy from "./Pages/Legals/PrivacyPolicy";
import FeesAndPaymentsPolicy from "./Pages/Legals/FeesAndPaymentsPolicy";
import ShippingAndDeliveryPolicy from "./Pages/Legals/ShippingAndDeliveryPolicy";
import ReturnRefundAndCancellationPolicy from "./Pages/Legals/Return-Refund-And-Cancellation-Policy";
import EditorialPolicy from "./Pages/Legals/EditorialPolicy";
import Verfied from "./Pages/VerfiedPage/Verfied";
import Contact from "./Pages/Introduction/Contact";
import About from "./Pages/Introduction/About";
import ABDM from "./Pages/Introduction/ABDM";
import Safety from "./Pages/Introduction/Safety";
import ContactNav from "./components/Navbar/ContactNav";
import AvijoAlpha from "./Pages/AvijoAlpha";
import AvijoExpert from "./Pages/AvijoExpert";
import DoCare from "./Pages/Introduction/DoCare";
import HealthAssistant from "./Pages/Introduction/HealthAssistant";
import DownloadApp from "./Pages/Introduction/DownloadApp";
import ChooseAcc from "./Pages/Introduction/ChooseAcc";
import GenerateId from "./Pages/GenerateId";
import AbdmOtp from "./Pages/Users/AbdmOtp";
import HfrRegister from "./Pages/HfrRegister";
import Blogs from "./Pages/Blogs";
import Blog from "./components/Blog";
import AvijoHome from "./Pages/AvijoHome";
import HppHome from "./Pages/HppHome";
import FaviconChanger from "./Pages/FaviconChanger";


const App = () => {
  const location = useLocation();

  useEffect(() => {
    localStorage.setItem("myPath", location.pathname);
  }, [location.pathname]);

  const currentPath = location.pathname;


  const pathsToHideNavbar = [
    "/user/login",
    "/user/otp-verify",
    "/user/create-account",
    "/lab/login",
    "/lab/otp-verify",
    "/privacy-policy",
    "/policy/shipping-and-delivery-policy",
    "/policy/editorial-policy",
    "/terms-and-conditions",
    "/policy/return-refund-and-cancellation-policy",
    "/policy/fees-and-payments-policy",
    "/verfied",
    "/contact",
    "/provider",
    "/",
  ];

  const isNavbarVisible = () => {
    for (let path of pathsToHideNavbar) {
      if (currentPath.startsWith(path)) {
        return false;
      }
    }
    return true;
  };

  const showContactNavbar = currentPath === "/about" || currentPath === "/abdm";
  const showSafetyNavbar = currentPath === "/safety";
  const showUserHome = currentPath === "/user/home";
  const showHeader = currentPath.startsWith("/user") && !showUserHome;

  const showNavbar = isNavbarVisible();

  return (
    <>
       <FaviconChanger /> {/*Add this component here to handle favicon changes */}
      {/* {showHPPProfileNavbar && <HPPProfileNavbar />} */}
      {showContactNavbar && <ContactNav />}
      {showSafetyNavbar && <ContactNav />}
      {/* {showProfileNavbar && <ProfileNavbar />} */}
      {showHeader && <UserHeader />}
      {showUserHome && <HomeNav />}
      {showNavbar && <HomeNavbar />}
      {/* {showProfileEditNavbar && <ProfileEditNav />} */}
      {/* {showHPPProfileEditNavbar && <HPPProfileEditNav />} */}
      {/* {showPharmacyProfileEditNavbar && <PharmacyProfileEditNav />} */}
      {/* {showLabProfileEditNavbar && <LabProfileEditNav />} */}
      <Routes>
        {/* Home Route */}
        <Route path="/" element={<AvijoHome />} />
        <Route path="/provider" element={<MainHome />} />
        {/* User Route */}
        <Route path="/user/home" element={<Home />} />
        <Route path="/user/login" element={<Login />} />
        <Route path="/user/otp-verify" element={<UserLoginOtp />} />
        <Route path="/user/create-account" element={<CreateAccount />} />
        {/* Doctor Route */}

        {/* legals */}
        <Route path="/feesandpaymentspolicy" element={<FeesAndPaymentsPolicy />} />
        <Route path="/editorialpolicy" element={<EditorialPolicy />} />
        <Route path="/returnrefundandcancellationpolicy" element={<ReturnRefundAndCancellationPolicy />} />
        <Route path="/shippingandDeliverypolicy" element={<ShippingAndDeliveryPolicy />} />
        <Route path="/privacypolicy" element={<PrivacyPolicy />} />
        <Route path="/policy/:option" element={<TermsAndConditions />} />
        {/* Verifed */}
        <Route path="/verfied" element={<Verfied />} />
        <Route path="/safety" element={<Safety />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/about" element={<About />} />
        <Route path="/abdm" element={<ABDM />} />
        <Route path="/alpha" element={<AvijoAlpha />} />
        <Route path="/expert" element={<AvijoExpert />} />
        <Route path="/docare" element={<DoCare />} />
        <Route path="/drjii" element={<HealthAssistant />} />
        <Route path="/downloadapp" element={<DownloadApp />} />
        <Route path="/pricing" element={<ChooseAcc />} />
        <Route path="*" element={<NotFound />} />
        {/*Abha Route */}
        <Route path="/blogs" element={<Blogs />} />
        <Route path="/blog/:id" element={<Blog />} />
        <Route path="/hpp" element={<HppHome />} />
      </Routes>
    </>
  );
};

const WrappedApp = () => (
  <Router>
    <App />
  </Router>
);

export default WrappedApp;
