import React, { useState } from "react"; 
import Footer from "../components/Home/footer/Footer";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import ExpertNav from "../components/Navbar/ExpertNav";
import { Modal } from "react-bootstrap";
import '../styles/Home.css';

const Home = () => {
  // Hero Component States
const [show, setShow] = useState(false);
  const [modalShow, setModalShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleModalClose = () => setModalShow(false);
  const handleModalShow = () => setModalShow(true);
 
  return (
    <div>
      <Helmet>
        <title>For Provider | Avijo</title>
        <meta
          name="description"
          content="Explore Avijo’s cutting-edge solutions for healthcare providers, including EMR/EHR tools, cloud software, and advertising platforms. Empower your practice with innovative technology."
        />
      </Helmet>
      <ExpertNav/>
      <div className="pt-5">
      <div className=" h-auto flex flex-col md:flex-row items-center gap-10 md:gap-40 bg-gradient-to-r from-[#0097DB] via-[#0c93ed98] to-[#feffff] w-full rounded-1xl px-5  ">
  <div className="w-full md:w-1/2 font-[Gilroy-SemiBold] text-left pl-10">
    <h1 className="text-white text-5xl  md:text-8xl  leading-tight mb-4">
      avijo
    </h1>
    <p className="text-white text-2xl md:text-4xl font-medium">
      Empowering Healthcare Provider's
    </p>
  </div>
  <img
    src="https://png.pngtree.com/png-clipart/20231003/original/pngtree-online-doctor-health-service-png-image_13230792.png"
    alt="Medical theme"
    className="w-[70%] md:w-[32%] h-auto max-w-xl rounded-lg"
  />
</div>
</div>


<div className="container px-5 text-center mx-auto">
  <h4 className="hero-what">What we do?</h4>
  <p className="hero-paragraph">
  We are on Mission to bring World Class Healthcare ecosystem by 
leveraging Technology to make healthcare accessible, affordable, Safe 
and convenient as a complaint of Ayushman Bharat Digital Mission(ABDM), UHI 
& HIPAA standards.
    
    <Link to="/about" className="text-decoration-none ps-4">
      Learn more..
    </Link>
  </p>

  <h4 className="hero-what">Ayushman Bharat Digital Mission (ABDM)</h4>
  <p className="hero-paragraph">
    The Ayushman Bharat Digital Mission (ABDM) aims to develop the
    backbone necessary to support the integrated digital health
    infrastructure of the country. It will bridge the existing gap amongst
    different stakeholders of Healthcare ecosystem through digital
    highways.

    <Link to="/ABDM" className="text-decoration-none ps-4">
      Learn more..
    </Link>
  </p>
  
  <div>{/* <LimitedParagraph text={abdmtext} limit={150} /> */}</div>
</div>

<Modal show={modalShow} onHide={handleModalClose}>
  <Modal.Header closeButton>
    <Modal.Title>Download Avijo Health app</Modal.Title>
  </Modal.Header>
  <Modal.Body>
    <h6>
      Avijo health app is now in beta version after testing we notify you
    </h6>
  </Modal.Body>
</Modal>


{/* HEALTHINFO  SECTION */}
<section className="text-gray-600 body-font relative">
  <div className="container py-24 mx-auto">
    <div className="flex flex-wrap">
      <div className="w-full md:w-1/2 mb-6">
        <iframe
          width="100%"
          height="540"
          src="https://www.youtube.com/embed/bhjb_HHB8Ik?si=klGEALTUK9K6xRH2"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          referrerpolicy="strict-origin-when-cross-origin"
          allowfullscreen
        ></iframe>
      </div>
      <div className="w-full md:w-1/2 pl-6">
        <div className="w-full">
          <h3 className=" text-4xl text-[#0097DB] text-start mb-6">
            <span className="text-start text-black mb-6 text-[44px] font-[Gilroy-SemiBold] leading-tight text-[#0097DB]">HFR by avijo</span>
            <span className="text-[#12CDB7] italic text-[35px]"> Alpha</span>
          </h3>
          <p className=" userApp-para text-base text-justify text-gray-600 leading-6 mb-6">
            Health Facility Registry (HFR) is a comprehensive repository. It includes public and private health facilities such as hospitals, clinics, pharmacies, and diagnostic centers. By registering your facility through Avijo Alpha, this enhances your facility's visibility and credibility. Registration facilitates better access to healthcare resources, enables seamless management & integration with other healthcare providers & patients, and ensures that patients can easily find and trust your services. Embrace the digital future of healthcare and expand your reach by registering with the Health Facility Registry (HFR) through Avijo Alpha today.
          </p>
          <div className="health-para text-justify">
            <p> ✔ Visibility in Listings.</p>
            <p> ✔ Management of Facility.</p>
            <p> ✔ Smart Notifications systems.</p>
            <p> ✔ Smart Calling System.</p>
            <p> ✔ HIPAA Servers.</p>
          </div>
          <Link to="/" className="health-btn text-decoration-none pt-6 ps-3">
            Learn More / Register as Health Id
          </Link>
        </div>
      </div>
    </div>
  </div>
</section>


{/* 
HEALTHPROVIDEDR SECTION */}
<section className="text-gray-600 body-font relative">
  <div className="container py-24 mx-auto w-full">
    <div className="flex flex-wrap justify-between w-full">
      <div className="w-full md:w-1/2 pr-6 mb-6">
        <div className="w-full">
          <h3 className="text-start text-black mb-6 text-[44px] font-[Gilroy-SemiBold] leading-tight text-[#0097DB]">
            HPR by avijo
            <span className="text-[#FD7979] italic text-[35px]"> Expert</span>
          </h3>
          <p className=" userApp-para text-[15px] leading-[24px] text-justify">
            Healthcare Professionals Registry (HPR) is a comprehensive repository of all healthcare professionals (e.g., Doctors, nurses) across both modern and traditional systems of medicine delivering healthcare services across India. By joining the HPR through Avijo Expert, you gain access to a network of registered professionals and can offer and manage your services more effectively across India.
          </p>
          <div className="health-para text-justify">
            <p> ✔ Visibility in Listings.</p>
            <p> ✔ Management of Facility.</p>
            <p> ✔ Smart Notifications systems.</p>
            <p> ✔ Smart Calling System.</p>
            <p> ✔ HIPAA Servers.</p>
          </div>
          <Link to="/expert" className="health-btn text-decoration-none pt-3 ps-3">
            Learn More / Register as HPR
          </Link>
        </div>
      </div>
      <div className="w-full md:w-1/2 mb-6">
        <iframe
          width="100%"
          height="470"
          src="https://www.youtube.com/embed/PAIGX5n290c?si=JXkC8GQkiCMhepGN"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          referrerpolicy="strict-origin-when-cross-origin"
          allowfullscreen
        ></iframe>
      </div>
    </div>
  </div>
</section>


  {/* ABHA CREATION SECTION */}
  <section className="text-gray-600 body-font relative w-full">
  <div className="container py-24 mx-auto w-full">
    <div className="flex flex-wrap justify-between w-full">
      <div className="w-full md:w-1/2 pr-6 mb-6">
        <iframe
          width="100%"
          height="520"
          src="https://www.youtube.com/embed/5ZvLR1DcNBo?si=fvxaNiH408NPVpzh"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          referrerpolicy="strict-origin-when-cross-origin"
          allowfullscreen
        ></iframe>
      </div>
      <div className="w-full md:w-1/2 mb-6">
        <h3 className="text-start font-[Gilroy-SemiBold] text-black mb-6 text-[44px] leading-tight text-[#0097DB]">
          ABHA Creation by <span className="font-[Gilroy-SemiBold] text-[#0097db]">avijo</span>
        </h3>
        <div className="w-full">
          <p className="userApp-para text-[15px]  leading-[24px] text-justify">
            By creating ABHA/Health id by avijo you gain one stop solution for healthcare needs, seamless access to teleconsultations, unified health tracking and record management, quick deliveries and lab sample collection, all powered by Ai for better health management and more..
          </p>
          <div className="health-para text-justify">
            <p> ✔ Find nearby doctors, hospitals & labs with a map.</p>
            <p> ✔ Health tracking: Track fitness, meds, and records.</p>
            <p> ✔ Ai assistant (Dr. Jii): Personalized health insights & reminders.</p>
            <p> ✔ Book appointments, video consultations with doctors.</p>
            <p> ✔ Instant ambulance booking and service.</p>
            <p> ✔ Instant medicine and health product delivery.</p>
            <p> ✔ Instant sample collection for lab tests.</p>
          </div>
          <Link to="/" className="health-btn text-decoration-none pt-6 ps-3">
            Learn More / Register as Health Id
          </Link>
        </div>
      </div>
    </div>
  </div>
</section>


{/* AVIJOhpp SECTION   */}

<section className="text-gray-600 body-font relative">
  <div className="container py-24 mx-auto w-full">
    <div className="flex flex-wrap justify-between w-full">
      <div className="w-full md:w-1/2 mb-6">
        <div className="health-left-sec">
          <h3 className="text-start text-black mb-6 text-[44px] font-[Gilroy-SemiBold] text-[#0097DB]">
            avijo <span className="text-[#FFCA38] text-[35px] italic">HPP</span>
          </h3>
          <p className="userApp-para text-[20px]  leading-[24px] text-justify">
            Avijo HPP application developed for Health Product Provider (HPP) companies, including pharmaceutical firms, medical device manufacturers, and suppliers. This platform allows HPP companies to manage their product listings, track inventory, process orders, and interact with healthcare facilities and professionals. Avijo aims to streamline the supply chain in the healthcare industry, ensuring timely delivery of essential medical products and fostering a more connected and responsive healthcare ecosystem.
          </p>
          <div className="health-para text-justify">
            <p> ✔ Create Company Account.</p>
            <p> ✔ Add/Edit catalog.</p>
            <p> ✔ Live order tracking in B2B trade.</p>
            <p> ✔ Advertising on platform for B2B & B2C.</p>
          </div>
          <Link to="/hpp" className="health-btn text-decoration-none pt-3 ps-3">
            Learn More / Register as HPP Company
          </Link>
        </div>
      </div>
      <div className="w-full md:w-1/2 pl-6 mb-6">
        <iframe
          width="100%"
          height="455"
          src="https://www.youtube.com/embed/ICX5Hw5shNw"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          referrerpolicy="strict-origin-when-cross-origin"
          allowfullscreen
        ></iframe>
      </div>
    </div>
  </div>
</section>

      <Footer />
    </div>
  );
};

export default Home;
