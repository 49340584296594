import React from "react";
import App from "../components/Users/header/Header";
import Footer from "../components/Home/footer/Footer";
import { Helmet } from "react-helmet";
import '.././styles/AvijoHome.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faSquareArrowUpRight } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import Login from "./Users/Login";

export default function AvijoHome() {

    return (
        <>
        <Helmet>
                <title>Avijo: The Health App</title>
                <meta
                    name="description"
                    content="Download the Avijo Health App for seamless access to healthcare services. Book consultations, order medicines, track health records, and manage your well-being conveniently."
                />
                 <link rel="icon" href="./avijomain.ico" data-react-helmet="true"/>
            </Helmet>

                <div className="w-full h-full items-center flex flex-col">
            <div className="w-full">
                <App />
            </div>
            <div className="flex md:flex-row flex-col items-center w-full justify-center"  id="container.main">
                <div className="flex flex-row items-centre justify-center md:w-[50%] w-full">
                    <div className="flex flex-col items-left justify-center w-[70%] ">
                    
                        <h1 className="w-[90%] text-black text-[30px] font-bold pt-[20%]" id="heading-home">India's Fastest Healthcare Delivery
                        </h1>
                        <div className="container.button">
                        <div className="button-container" >
                           <Link to="/downloadapp">
                                <button className="download-button">Download </button>
                           </Link>
                        </div>
                        </div>
                    </div>
                </div>
                <div className="flex flex-col items-left justify-center md:w-[50%] w-full">
                    
                  
                    <h3 className=" text-black text-[24px] font-[Gilroy-SemiBold] pt-[10%] md:w-[50%]" id="avijo-text"> avijo : 
                    one stop health app</h3>
                    <p className="text-black text-[15px] font-[Gilroy-SemiBold] md:w-[30%]" id="para-health"> avijo is a personalized healthcare companion, providing seamless access to teleconsultations, health tracking, quick medicine delivery, and lab sample collection, all powered by AI for better health management.
                        </p>
                    <p className="home-paragraph">
                            <p> <FontAwesomeIcon icon={faCheck} style={{color:"#323232cd "}}/> <u>Teleconsultation</u> : Instant access to doctors online.</p>
                            <p><FontAwesomeIcon icon={faCheck} style={{color:"#323232cd "}}/>  <u>Health Tracking</u> : Track fitness, meds, and records.</p>
                            <p> <FontAwesomeIcon icon={faCheck} style={{color:"#323232cd "}}/> <u>Quick Commerce</u> : Fast delivery of medicines & samples.</p>
                            <p><FontAwesomeIcon icon={faCheck} style={{color:"#323232cd "}}/>  <u>AI Assistance (Dr. Jii)</u> : Personalized health insights & reminders.
                            </p>
                            <p><FontAwesomeIcon icon={faCheck} style={{color:"#323232cd "}}/>  <u>Docare Community</u> : Connect with healthcare professionals</p>
                            <p><FontAwesomeIcon icon={faCheck} style={{color:"#323232cd "}}/> <u>Seamless Integration</u> : Sync health records for better care.</p>
                            </p>
                    <div className="get-link-container">
                    <h4 className="text-black text-[16px] font-[Gilroy-SemiBold] w-[60%] pt-2">Get the link to Download the App</h4>
                    <div className="flex flex-row items-center w-[60%] justify-between">
                        <input
                            type="text"
                            className="w-[80%] mb-2 h-[40px] border mt-2 p-3"
                            id="placeholder_entermob"
                            placeholder="Enter Your Mobile"
                        // value={phone}
                        // onChange={(e) => setPhone(e.target.value)}
                        />
                        <div className="h-[40px] border w-[100px] ml-2 rounded-sm items-center justify-center flex flex-col bg-[#0097DB]" id="send-sms-button">
                            <button><span className="text-white text-sm text-[Gilroy-SemiBold]">Subscribe</span></button>

                        </div>
                    </div>
                    </div>
                  </div>
                </div>
            
            {/* <div className="w-[80%] rounded-md bg-[#EDF4F6] h-[130px]"></div> */}
        </div>
        
        <div style={{ display: "none" }}>
        <Login />
      </div>
        <Footer />
        </>
    )
}