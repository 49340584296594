import React from "react";
import { Navbar, Container, Nav, Form } from "react-bootstrap";
import "../../../styles/Header.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAddressCard, faUser } from "@fortawesome/free-regular-svg-icons";
import { Link } from "react-router-dom";

function App() {
  return (
    <Navbar expand="lg">
      <Container>
        <Navbar.Brand href="/" className=" logo_navbar font-[Gilroy-SemiBold text-[calc(10vw+10px)] h-auto]">
          avijo
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto"></Nav>

          <Form.Control
            type="text"
            className=" search_bar"
            placeholder="Deliver To     |   Search for : Medicine & wellness products"
          />

          <Nav className="ms-auto">
          <Link 
                to="/provider" 
                className="d-flex align-items-center" 
                style={{ textDecoration: "none", color: "white" }}>
                <FontAwesomeIcon icon={faUser} />
                <span className="navbar_t">For Providers</span>
            </Link>
            <Nav.Link href="/contact" className="d-flex align-items-center">
            <FontAwesomeIcon icon={faAddressCard} style={{color:"white"}}/>
              <span className="navbar_t">Contact</span>
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default App;
