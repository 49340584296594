import React, { useEffect, useState } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import { Link, useNavigate } from "react-router-dom";
import ExpertNav from "../components/Navbar/ExpertNav";
import Footer from "../components/Home/footer/Footer";
import { useHistory } from "react-router-dom";
import { Helmet } from "react-helmet";



const AvijoExpert = () => {

    const navigate = useNavigate();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);


    return (
        <div className="App">
            <ExpertNav />
            <Header />
            <Helmet>
            <title> Avijo Expert | Avijo</title>
            <link rel="icon" href="./avijoexpert.ico"/>
            <meta name="discription" content="Discover Avijo Expert, a powerful EMR/EHR Ai powered tool tailored for doctors and healthcare professionals to Streamline Daily operations and practice, improve patient care and manage records effortlessly for free."></meta>
          </Helmet>
            <Carousel />
            <SupportData />
            <Footer />
        </div>
    )
}

const Header = () => {
    return (
        <header className="hero bg-gradient-to-r from-[#ECF9FF] to-[#C3EBFE]">
            <div className="flex flex-col md:flex-row justify-between p-4">
                <div className="flex flex-col p-2 w-full md:w-1/2 h-full md:mr-12">
                    <div>
                        <div className="flex flex-row items-end">
                        <h1 className="font-semiBold text-[45px] text-[#535353]" style={{ fontFamily: 'Gilroy-SemiBold' }}>avijo</h1><h6 className="text-xl text-[#FD7979] italic">Expert</h6>
                           
                        </div>
                        <span className="font-gilroy font-[500] text-xl text-[#535353] text-left">
                            Avijo Expert is the premier application designed specifically for healthcare doctors/professionals. Built to complement the Avijo Alpha platform, Avijo Expert empowers doctors and medical practitioners with the tools they need to manage their practice, engage with patients, and contribute to a growing health community—all from the convenience of their devices.
                        </span>
                    </div>
                    <button className="bg-[#0097DB] h-12 w-[130px] rounded-lg mt-9 flex items-center justify-center">
                        <a href="https://expert.avijo.in/"
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{ textDecoration: "none", color: "white" }}
                            className="flex items-center gap-2 text-lg">
                            Get Started
                            <img
                                src={require("../Assets/image/diagonal_arrowWhite.png")}
                                className="h-[14px] w-[18px]" />
                        </a>
                    </button>
                </div>
                <div className="p-12 w-full md:w-1/2 md:ml-20 flex justify-center">
                    <img src="https://cdni.iconscout.com/illustration/premium/thumb/healthcare-equipment-illustration-download-in-svg-png-gif-file-formats--medical-pattern-first-aid-kit-medicine-doctor-pharmacy-pregnancy-pack-people-illustrations-4494350.png"
                        alt="header"
                        className="h-[350px] w-auto" />
                </div>
            </div>
        </header>
    );
}

const CarouselItem = ({ active, index, title, content, buttonText, imgSrc, bgColor, setActive }) => (
    <div className={`${active === index ? 'active' : ''} carousel-item flex flex-col items-center`} style={{ backgroundColor: bgColor }}>
        <h2 className="self-center text-center ml-[20%] w-[60%] pt-5 text-4xl">Elevate Your Medical Practice with Cutting-Edge Technology</h2>
        <div className="flex flex-col md:flex-row items-center justify-between mt-5 w-[90%] text-center ml-10">
            {['Patient care', 'Remote Working', 'AI optimize', 'Online Reach', 'Docare'].map((text, idx) => (
                <div key={idx} onClick={() => setActive(idx)}>
                    <span className={`font-semibold text-[22px] text-center hover:text-white ${active === idx ? 'text-[#0097DB]' : 'text-black'}`}>{text}</span>
                </div>
            ))}
        </div>
        <div className="flex flex-col md:flex-row justify-between p-4 pt-2 items-center">
            <div className="flex flex-col p-2 w-full md:w-[65%] h-full">
                <div className="flex-grow">
                    <h1 className="text-[36px] font-gilroy font-semiBold text-black ml-4">{title}</h1>
                    {content.map((item, idx) => (
                        <div key={idx} className="flex flex-row items-start mt-2">
                            <img src={require('../Assets/image/arrow.png')} className="w-[9px] h-[13px] mr-2 mt-1" alt="arrow" />
                            <span className="text-black text-[20px] font-gilroy font-mdeium">{item}</span>
                        </div>
                    ))}
                </div>
                <button className="bg-[#0097DB] p-2 w-[220px] rounded-lg mt-4">
                <a href="https://expert.avijo.in/"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="flex items-center gap-2 text-lg text-white no-underline">
                    <span className="text-sm text-white">{buttonText}</span>
                    <img
                            src={require("../Assets/image/diagonal_arrowWhite.png")}
                            className="h-[14px] w-[18px]"
                            alt="arrow"
                        />
                        </a>
                </button>
            </div>
            <div className="p-4 w-full md:w-[500px] h-[430px] flex justify-center">
                <img src={imgSrc} alt="header" className="h-[80%] w-[80%]" />
            </div>
        </div>
    </div>
);


const Carousel = () => {
    const [active, setActive] = useState(0);

    const items = [
        {
            index: 0,
            title: 'Comprehensive Patient Management',
            content: [
                'Centralized platform for managing patient records, appointments, and communications.',
                'Easy access to patient history and diagnostic reports for informed decision-making',
                'Secure storage and retrieval of patient data compliant with ABDM standards.',
            ],
            buttonText: 'Register as HPR for demo',
            imgSrc: 'https://s3-alpha-sig.figma.com/img/4617/de20/481045b8c3ebcf2928412728e4ac804a?Expires=1739145600&Key-Pair-Id=APKAQ4GOSFWCW27IBOMQ&Signature=Y~7gRhHb6woLxt~nEQC6ncDc-quUp4oY5aNh6tduftyO~eGkMFez~BOeKbsmxs7Th1BxtQ74FsucD3zUwKcG18h2SY9aKwc0zWdLjqe-ToAeNiS3Dntfu0pjpbymwjQPkJxlIdvLOHNTM4wfIse0Te2Y25stmA8PxdsUi~KLGrd1UvVApL5cV2Mwb8Hd~XyHzNJzA~~Nt9Qwst7LuhAaNxwA7XVeZRCDSwD3XxLFr2c~d~PEtGdYiTxmFWOXQvLWZIcb4PFogsMKcH17vVq~yQK8sAMaW-VGPA4p~kavaoacQmw-Wx07rxXkj85C3KUg0PoqX2nMfcT6KxiaTbCN~A__',
            bgColor: '#FCEFE7',
        },
        {
            index: 1,
            title: 'Teleconsultation Integration',
            content: [
                'Seamless video, audio, and chat-based consultation options.',
                'Integrated prescription generation and sharing with patients.',
                'Allows for remote diagnosis and follow-up, expanding patient reach.',
                'Real-time appointment booking and management.',
            ],
            buttonText: 'Register as HPR for demo',
            imgSrc: 'https://s3-alpha-sig.figma.com/img/8726/7fbc/803c58c63dcad6bda70802ffa3ef77d6?Expires=1739145600&Key-Pair-Id=APKAQ4GOSFWCW27IBOMQ&Signature=VOtNiobU3OG1FViysTZZE9S7KHEFblOpJ0vDWzpNh4xZVA-PtSxC3gOfVR~HQTOUN8q7o-fSmV1uYJGmQltBDZfMu0xIE7FJq-L8jsKkz5fDQ1bJGcPXYl~IL3PyM8ZfFpNkpZ-x8AXtJGkSOEqJqTEe14rSvPceg4OraaKghWaOx3iyGFHAyRuMvuePRYzPFJqRubk8PsbgVf5IhhUk0-Vve0S4JvfaMklmTeSslzfyhYqCfCgRLY~3mz6A8vLeGG2~Akb6FW6yOF~0nSPWmMHRmRfWenBrTaF5QbTvTHLxKy9qYEb0T08tQPPl5SRcQX~vWjivQY8TbwZMoXprxg__',
            bgColor: '#FAEFD4',
        }, {
            index: 2,
            title: 'AI-Powered Insights and operation',
            content: [
                'Utilize AI for patient treatment recommendations and insights..',
                'Analyze patient data for trends and predictive healthcare outcomes..',
                'Support clinical decisions with AI-driven diagnostic tools..',
            ],
            buttonText: 'Register as HPR for demo',
            imgSrc: 'https://s3-alpha-sig.figma.com/img/073d/b412/689224b2ce2eb402ec6f418fac14f228?Expires=1739145600&Key-Pair-Id=APKAQ4GOSFWCW27IBOMQ&Signature=e~QVueyZJCThHia9brN0LjIeqFsbZnGZGAf-4OYcnsJ9BJUWp3CE3D~PZKmPph-5NrVrBxET~4sIvFhj0kbJE2SrBZS24aTxpOQH1Muy7YBeyFaquFD8jyJC0dKdSW~SNv~FQQgFzouu9OLV4t-hcVugfks1dRjCIWh~AsDaErgfr23V8ZesPUFrjEbfHrQZ9JLxp9KdT2vOr3SA7H4OU-SnZH0TCes8zVV0sIGBGxssVlq69ITqnsxfIGfSW3A66OCRaNVzAUlCkVz73bdYqDvgtqRiMKbiZQZ4B9hXSxwbvp~o1NLXbQA159wp-Ci7M9PEt-gdiwL2a1siqe5MfA__',
            bgColor: '#E4DDFF',
        }, {
            index: 3,
            title: 'Teleconsultation & Appointment Booking',
            content: [
                'Offers a user-friendly interface for patients to book appointments online.',
                'Supports video, audio, and chat-based consultations',
                'Provides a secure platform for sharing medical reports and prescriptions.',
                'Integrates with electronic health records for seamless consultations',
            ],
            buttonText: 'Register as HPR for demo',
            imgSrc: 'https://s3-alpha-sig.figma.com/img/2ead/b998/5709459b4fb969d22ec77e944575d582?Expires=1739145600&Key-Pair-Id=APKAQ4GOSFWCW27IBOMQ&Signature=f9s5udMtNKtDyqRVYa49tb1PciZ04u2pOULZQ17wsBlQrvM6ITdl1TfCBZVxLA6650Aw8IjfHzijWOfI~YOtohXtGq916cJzug2OxELFRlt4hacQJf0kIdlh85WK9GMYZGqjDlJbb9hrGsQgftes0fvz4CWv2eAGYntp3IgY5eRqwH7nnfUdoYTtfbP1DLqw1SVfTSBYwT7cuVJV~O19HGlqmv3bgPXwyltCJzSxknr-wgBw2G8BkGoromXbH5vIb83kLfpyzCOfRnbgeJs~6kWkNkwzyV2uHkTenVbkVtbVA1Lj8GC-zvBP2dPIypfq2eS~uxVa5l6n1Q-4S5KZmw__',
            bgColor: '#E0EBFF',
        }, {
            index: 4,
            title: 'Doctor & Patient Community Integration',
            content: [
                'Enables doctors to build their professional network within the healthcare community',
                'Facilitates case discussions and peer reviews in a secure environment',
                'Offers a platform for collaborative learning and knowledge sharing',
                'Encourages patient engagement through Q&A sessions with doctors.',
            ],
            buttonText: 'Register as HPR for demo',
            imgSrc: 'https://s3-alpha-sig.figma.com/img/d397/5bf3/1494cbd1dd2b39464dd4ce25980ce76c?Expires=1739145600&Key-Pair-Id=APKAQ4GOSFWCW27IBOMQ&Signature=q4I0GQVnyIXUpkaHkxdvovX~~xgOdCLYNOj4JmhUcDs9PRZVY4p72gJ1j21xHTuAh8zgMHtD8p8LUR3ZvaduixiUvRS~4ySjEX1LsLjhqVsnABwVbuWr3WG11yaJLvPnJP3kufsWn7H0JK9W~hEC3UtpZ0tlGDY8NCCJ5AD~0VdTQ7xHmKb2o5PvBzWU7d35ZtTGoCzKmmhPvtdrP4xXHewZHUVmVtrMqoIOHLSwkhhvNmZinkP4-5QEVZ55iylqIJDs-Ner5zbYH7Q3YCneZYD26KHuTV-alERHsOydqUvWLuK16pr0AFcvCTK~Qg8-cgNrq~sQsriX4kk80W1e2g__',
            bgColor: '#FADCFF',
        }
    ];

    const handlePrev = () => {
        setActive(prev => (prev > 0 ? prev - 1 : items.length - 1));
    };

    const handleNext = () => {
        setActive(prev => (prev < items.length - 1 ? prev + 1 : 0));
    };

    return (
        <div id="carouselExample" className="relative">
            <div className="carousel-inner">
                {items.map(item => (
                    <CarouselItem
                        key={item.index}
                        active={active}
                        index={item.index}
                        title={item.title}
                        content={item.content}
                        buttonText={item.buttonText}
                        imgSrc={item.imgSrc}
                        bgColor={item.bgColor}
                        setActive={setActive} // Pass setActive function
                    />
                ))}
            </div>
            <button
                className="absolute top-1/2 left-0 transform -translate-y-1/2 p-2"
                onClick={handlePrev}
                style={{ backgroundColor: 'transparent' }}
            >
                <img src={require('../Assets/image/left.png')} alt="Previous" className="w-8 h-8" />
            </button>
            <button
                className="absolute top-1/2 right-0 transform -translate-y-1/2 p-2"
                onClick={handleNext}
                style={{ backgroundColor: 'transparent' }}>
                <img src={require('../Assets/image/right.png')} alt="Next" className="w-8 h-8" />
            </button>
        </div>
    );
};


const SupportData = () => {

    const data = [
        {
            id: 0,
            heading: 'Profit & Growth Opportunities',
            image: 'https://s3-alpha-sig.figma.com/img/050a/a594/25ad2f16d7fa6745f3d6fecf67440a5c?Expires=1739145600&Key-Pair-Id=APKAQ4GOSFWCW27IBOMQ&Signature=MrS5v~6RS8z8wGlzAaNC6ZMXkh0DdIS~JVstQrTZaGIiJfIXYCRKOeDw0QD~DwjL7EaciSB8--qctdnnUFAJlCkelMzgHSRekJEk0wjOrK1RXsWMVNgKQtCFp80ywOpYlj3kyAkvj27bRZREVRm01SqemWh1jAtcTORmYWmaV0GS0AkYHdwnoDdh-3fbrg~I6dmeUTp2UC7NcUjP080N9dZVU9dsuqWQeTv3IKXO2jU5mIVTCvP-LpMHJiJablx~QFDjglR7FEEksc8dXWsRdFYlrT9Y1lOO3Yog3ZI~xDDmCHK0DqrpJmtJ6xZr6pPlfZ4UMi8K8vkJtAUzwMY5ug__',
            points: [
                {
                    id: 0,
                    text: 'Avijo Expert isn’t just about managing your practice—it’s about growing it. The app provides opportunities for additional revenue through profit margins on diagnosis and medicine prescriptions. You can also participate in our affiliate program, recommending trusted healthcare products to your patients through the Avijo platform, and earning commissions on every sale.'
                }
            ]
        },
        {
            id: 1,
            heading: 'Join the Docare Community',
            image: 'https://s3-alpha-sig.figma.com/img/7c91/b4dd/12703bb802bdfefddc314923ecc6d76a?Expires=1739145600&Key-Pair-Id=APKAQ4GOSFWCW27IBOMQ&Signature=E9rUyffC-YMLiEF49I7YVTt93~9XRNod6q8RVrguV-2IfzSWhZBjjfp2-NS6xR7l9Qi2nAN4Iqs0iNQ~maWYUGmF~kJ4Qr832sOkZnCm8V~ta8dGFptHQi096JEX7r-I6UkXTZXOLQmGcY4JJql9OkAIOqwauYJtJaETGYdXu3slGbnri9QCoJbB7FTr76VPK8Kl3EYWv41373zz4pkGK3r-AmTC3ZaBUvxcSws5fBXTXto~rb-GdBFQK96Px0H60I8XCn2BcBjZfzqmhnXBwIO3ii2GBv-WhsfiLEbCI8S-7zAJb8RMihd64uRjj8EwFPZMfMl6rTYoKO-RhUgmOg__',
            points: [
                {
                    id: 0,
                    text: 'Enhance your professional presence by joining Docare, Avijo’s health community. As part of Docare, you can create and share health-related content, engage in meaningful discussions with other healthcare professionals, and increase your visibility to patients seeking expertise in your specialty. Whether you’re looking to educate the public, share insights with peers, or simply network, Docare offers a platform to amplify your voice .'
                }
            ]
        },
        {
            id: 2,
            heading: 'Get Started with Avijo Expert',
            image: 'https://s3-alpha-sig.figma.com/img/580b/3fb4/8e465324458cadb0d32ea32300a280b6?Expires=1739145600&Key-Pair-Id=APKAQ4GOSFWCW27IBOMQ&Signature=qLr168oxrg4fkdJwWgT-SPFBpfZxVv0tDkYqHoAEF1HQONxqCEU4pi3q7Yhi8tVDU5IAVhcdph7Miq4txLr77C3Km-Tle~iGYoDjsPN5kdbHRjEItzZL44RB7HqWuexltcUpTrg2IBKbroykFQAePnvjjccvHju-WR0WkQikjIY6Pa0Q55izvZXcbVc4j1pdXReIak0V69n13Jm7Cgt0NMO-2mCM24vo21BV~3J4A4lsTCZj0~pFNPPpedZ1BbMeX03MYPVUoYxhf9kce1F~ajDIqcPnasmW3Kw~DzYQUr7Em9nRUBtCNDlHn6uXQW3jBad7DsaKkrbehUxvITOC3g__',
            points: [
                {
                    id: 0,
                    text: 'Avijo Expert is more than just an app—it’s a comprehensive tool designed to support your growth as a healthcare professional. Whether you’re looking to streamline your practice, increase your patient base, or contribute to a broader healthcare community, Avijo Expert has the features and functionality you need.'
                }
            ]
        },
    ]

    return (
        <>
            {data.map((item, index) => (
                index % 2 === 0 ? (index % 2 === 0 &&
                    <div key={index} className="flex flex-col md:flex-row pb-4 border-1 rounded-[5px] m-4 justify-between items-center">
                        <div className="flex flex-col p-2 w-full md:w-1/2 h-full">
                            <div className="flex-grow">
                                <h3 className="text-[36px] font-gilroy font-semiBold">{item.heading}</h3>
                                <ul className="list-disc pl-0">
                                    {item.points.map((point, idx) => (
                                        <div key={idx} className="text-[20px] text-[#424242] font-gilroy font-medium mt-2">
                                            <p className="expert-paragraph">{point.text}
                                            </p>
                                        </div>
                                    ))}
                                </ul>
                            </div>
                        </div>
                        <div className="p-4 pb-0 w-full md:w-1/2">
                            <img
                                src={item.image}
                                className="h-[310px] w-[500px] rounded-2 md:ml-10"
                            />
                        </div>
                    </div>
                ) : (
                    <div key={index} className="flex flex-col md:flex-row pb-4 border-1 rounded-[5px] m-4 justify-between items-center">
                        <div className="p-4 pb-0 w-full md:w-1/2">
                            <img
                                src={item.image}
                                className="h-[310px] w-[500px] rounded-2"
                            />
                        </div>
                        <div className="flex flex-col p-2 w-full md:w-1/2 h-full">
                            <div className="flex-grow">
                                <h3 className="text-[36px] font-gilroy font-semiBold">{item.heading}</h3>
                                <ul className="list-disc pl-0">
                                    {item.points.map((point, idx) => (
                                        <div key={idx} className="text-[20px] text-[#424242] font-gilroy font-medium mt-2">
                                            <p className="expert-paragraph">{point.text}
                                                <Link to="/DoCare" className="text-decoration-none ps-2">
                                                    Learn more..
                                                </Link>
                                            </p>
                                        </div>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    </div>
                )
            ))}
        </>
    )
}


export default AvijoExpert;
