import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import ExpertNav from "../components/Navbar/ExpertNav";
import Footer from "../components/Home/footer/Footer";
import { Helmet} from "react-helmet";
const HppHome = () => {

    useEffect(()=>{
        window.scrollTo(0, 0);
    },[]);

    return (
        <div className="App w-[100%]">
            <ExpertNav />
            <Header />
            <Helmet>
                <title>Avijo HPP | Avijo</title>
                <meta name="discription" content="Connect with healthcare professionals and facilities through Avijo HPP. Promote health products and medicines, advertise effectively, and build partnerships in the healthcare ecosystem."/>
                <link rel="icon" href="./avijohpp.ico"/>
            </Helmet>
            <Carousel />
            <Section1 />
            <Section2 />
            <Footer />
        </div>
    )
}


const Header = () => {
    const navigate = useNavigate();
    return (
        <header className="hero bg-gradient-to-r from-[#F6F6F6] to-[#C8C8C8]">
            <div class="flex flex-col md:flex-row justify-between p-4">
                <div className="flex flex-col p-2 w-full md:w-1/2 h-full">
                    <div className="flex-grow">
                        <span className="text-[16px] font-gilroy font-[600] text-[#535353]">
                            AI-POWERED SUPPLY CHAIN MANAGEMENT PLATFORM
                            AUTOMATE & OPTIMIZE END-TO-END SUPPLY CHAIN OPERATIONS
                        </span>
                        <div className="flex flex-row items-end">
                        <h1 className="font-semiBold text-[45px] text-[#535353] font-[Gilroy-SemiBold]">avijo</h1><h6 className="text-xl text-[#FFCA38] italic">HPP</h6>
                        </div>
                        <span className="text-xl text-[#535353] mb-5 font-[500]">
                            Kickstart your digitalisation journey today!
                        </span>
                    </div>
                    <span className="text-[16px] font-gilroy font-[400] text-[#535353] mt-2">
                        Struggling with inefficient outdated manual processes, error-prone data management, and lack real-time visibility.
                    </span>
                    
                    <button className="bg-[#0097DB] h-12 w-[130px] rounded-lg mt-9 flex items-center justify-center">
                    <a href="https://hpp.avijo.in/"
                    target="_blank"
                    rel="noopener noreferrer" 
                    style={{ textDecoration: "none", color: "white" }} 
                    className="flex items-center gap-2 text-lg">
                            Get Started
                            <img
                        src={require("../Assets/image/diagonal_arrowWhite.png")}
                        className="h-[14px] w-[18px]" />
                    </a>
                    </button>
                </div>
                <div className="p-4 w-full md:w-1/2">
                    <img src='https://www.mynuvancehealthbenefits.org/commonImages/login_main.png' alt="header" className="h-80 w-auto md:ml-20 pl-12" />
                </div>
            </div>
        </header>
    );
}

const Carousel = () => {
    return (
        <div id="carouselExample" class="carousel slide">
            <div class="carousel-inner">
                <div class="carousel-item active flex flex-col items-center bg-[#FFF0F0]">
                    <h2 className="self-center text-center ml-[20%] w-[60%] pt-5 text-4xl">Efficiency, Visibility, Control.</h2>
                    <div className="flex flex-row items-center justify-center mt-2 w-[90%] ml-10">
                        <span className="text-black font-semibold text-[32px]">Optimise your operations and elevate your success with avijo HPP. </span>
                    </div>
                    <div class="flex flex-col md:flex-row justify-between p-4 items-center">
                        <div className="flex flex-col p-2 w-full md:w-[60%] h-full">
                            <div className="flex-grow">
                                <h1 className="text-2xl text-black ml-4">Comprehensive Patient Management</h1>
                                <h1 className="text-lg text-black ml-4">Real-Time Visibility</h1>
                                <div className="flex flex-row items-start mt-2">
                                    <img src={require('../Assets/image/arrow.png')} className="w-[9px] h-[13px] mr-2 mt-1" />
                                    <span className="text-black text-sm font-[400]">Gain transparency & control of your operations with end-to-end visibility of upply chain, from order to delivery.</span>
                                </div>
                                <h1 className="text-lg text-black ml-4">Enhanced Business Agility & Efficiency</h1>
                                <div className="flex flex-row items-start mt-2">
                                    <img src={require('../Assets/image/arrow.png')} className="w-[9px] h-[13px] mr-2 mt-1" />
                                    <span className="text-black text-sm font-[400]">Provides a unified dashboard for real-time monitoring of healthcare operations</span>
                                </div>
                                <h1 className="text-lg text-black ml-4">Seamless Collaboration on One Platform</h1>
                                <div className="flex flex-row items-start mt-2">
                                    <img src={require('../Assets/image/arrow.png')} className="w-[9px] h-[13px] mr-2 mt-1" />
                                    <span className="text-black text-sm font-[400]">Ensures compliance with national and international healthcare regulations</span>
                                </div>
                            </div>
                            <div className="flex flex-row">
                                <button className="bg-[#0097DB] p-2 w-1/8 rounded-lg mt-4 border-1 border-[#0097DB] mr-4">
                              
                                 <a href="https://hpp.avijo.in/"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="flex items-center gap-2 text-lg text-white no-underline">
                                <span className="text-sm text-white">Try it</span>
                               <img
                                     src={require("../Assets/image/diagonal_arrowWhite.png")}
                                    className="h-[14px] w-[18px]"
                                    alt="arrow"/></a>
                    
                                </button>
                                <button className="bg-[#0097DB] p-2 w-40 rounded-lg mt-4">
                                <a href="https://hpp.avijo.in/"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="flex items-center gap-2 text-lg text-white no-underline">
                                    <span className="text-sm text-white"> Register for Demo</span>
                                    <img
                            src={require("../Assets/image/diagonal_arrowWhite.png")}
                            className="h-[14px] w-[18px]"
                            alt="arrow"/></a>
                                </button>
                            </div>
                        </div>
                        <div className="p-4 w-[500px] h-[260px] md:w-[40%]">
                            <img src='https://s3-alpha-sig.figma.com/img/93e8/2579/bc268683ab32d11186aa2ebc29108500?Expires=1739145600&Key-Pair-Id=APKAQ4GOSFWCW27IBOMQ&Signature=B7abdQGCzdsYpFgjDARgjf9gpu66gmMhtD-2jlTCmdVmJfChk98h69PuFYQvp7bQ4vWMoF157VTNLn6EXi8OeCjLB7O49yH53JfCXilmaQphPzZR3~9hhBy4z2Ca7wag2tdmiIU9orM58rPDbhafvo5mwOphangqQFyAVWBlvdTaGgLO9mQuWLOPvKiAXtLspmaEEIjYlSsSNj22M5eKwN5qJcTpwqIiDp7qtKbWI7drGW5SNw7NSgoSEEOvY0i3i0Wa1aqXitH1CrRhEU1oeup-LiByRFcpee5Gi3YTh9wrkBqW~yYiqr5jAyvMjI7oYifqtj~3N4cE7RkbvrhGUQ__' alt="header" className="h-[240px] w-[100%]" />
                        </div>
                    </div>
                </div>
                <div class="carousel-item flex flex-col items-center bg-[#FAEFD4]">
                    <h2 className="self-center text-center ml-[20%] w-[60%] pt-5 text-4xl">SUPPLY CHAIN MANAGEMENT SOLUTIONS</h2>
                    <div className="flex flex-row items-center justify-center mt-2 w-[90%] ml-10">
                        <span className="text-black font-semibold text-center text-[32px]">Optimize your operations and grow your business with our comprehensive solutions.</span>
                    </div>
                    <div class="flex flex-col md:flex-row justify-between p-4 items-center">
                        <div className="flex flex-col p-2 w-full md:w-[60%] h-full">
                            <div className="flex-grow">
                                <h1 className="text-2xl text-black ml-4">AI-Powered Operations</h1>
                                <div className="flex flex-row items-start mt-2">
                                    <img src={require('../Assets/image/arrow.png')} className="w-[9px] h-[13px] mr-2 mt-1" />
                                    <span className="text-black text-[20px] font-gilroy font-medium">Leveraging advanced technology and AI capabilities, we offer seamless connection, greater visibility, and meet operational needs in the Industry 4.0 era. Our user-friendly modular solutions cater to client needs, offering a dynamic, scalable, and flexible platform.</span>
                                </div>
                                <div className="flex flex-row items-start mt-2">
                                    <img src={require('../Assets/image/arrow.png')} className="w-[9px] h-[13px] mr-2 mt-1" />
                                    <span className="text-black text-[20px] font-gilroy font-medium">With deep domain expertise and extensive knowledge, as a global trading powerhouse in the automotive industry, avijo constantly progresses and builds capabilities to provide a one-stop supply chain management (SCM) solution.</span>
                                </div>
                            </div>
                            <div>
                            <button className="bg-[#0097DB] p-2 w-1/8 rounded-lg mt-4 border-1 border-[#0097DB] mr-4">
                                 <a href="https://hpp.avijo.in/"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="flex items-center gap-2 text-lg text-white no-underline">
                                <span className="text-sm text-white">Try it</span>
                               <img
                                     src={require("../Assets/image/diagonal_arrowWhite.png")}
                                    className="h-[14px] w-[18px]"
                                    alt="arrow"/></a>
                                </button></div>
                        </div>
                        <div className="p-4 w-[350] h-[350px] md:w-50">
                            <img src='https://s3-alpha-sig.figma.com/img/e612/975c/eb894bd953f066e804a816ce902ec273?Expires=1739145600&Key-Pair-Id=APKAQ4GOSFWCW27IBOMQ&Signature=Qw1oiIw~jw-7vtn6XIdXcsxn1OQhmbFAXDM2rSjrSDSCOkJnktbQJAtroOsOvnCmI9sB-azXHDiuh3A9tLBc2vva49EhacoymDjJIhMJImX6JpojJCLIp2SdRCcf~DFOLxWkw1bk4JRd-eChjErUb~TY1UC-WLWBfxJYxFTZLmfw2kJAoT2vmrhnO1vHeNuNcalU4mUx~AOu71ftjfFhRH7rGSv3Vwc4j3n5u5wMRD18MRlXvjDetOcqQkwYNcvJP5B3tAxvQhDL9-xj7eBnV5UD1~BGuNiwbA1e7jhqwJ~SBMJ0PusPtP1d49JWGUOzcH3RTnUeWH~cG57sBGovUQ__' alt="header" className="h-full w-full" />
                        </div>
                    </div>
                </div>
            </div>
            <button
                className="absolute top-1/2 left-0 transform -translate-y-1/2 p-2"
                data-bs-target="#carouselExample"
                 data-bs-slide="prev"
                style={{ backgroundColor: 'transparent' }}
            >
                <img src={require('../Assets/image/left.png')} alt="Previous" className="w-8 h-8" />
            </button>
            <button
                className="absolute top-1/2 right-0 transform -translate-y-1/2 p-2"
                data-bs-target="#carouselExample"
                data-bs-slide="next"
                style={{ backgroundColor: 'transparent' }}
            >
                <img src={require('../Assets/image/right.png')} alt="Next" className="w-8 h-8" />
            </button>
        </div>
    )
}

const Section1 = () => {
    return (
        <section className="w-[100%] flex flex-col items-center border-1 pb-5">
            <img src="https://s3-alpha-sig.figma.com/img/33df/b09e/9697362488f1500d7ef6147437d5ae27?Expires=1739145600&Key-Pair-Id=APKAQ4GOSFWCW27IBOMQ&Signature=MllaVu~m55mSiADyrSeubrh7ZN7g5J-6MvSdPy9U85NkTjFrUq0oLIudeNOBurh~SoHi9v-w-6n6my5QThdBmqahdcVT7uXbaATKaNrBmyOIj6mP0TSYQFKPRxkLX2Gw76xSwBf8CW0hqUIJd0wqDhIzciv6CznlqOP9~AjxX6GxqAqI0SPZzoeEIlkEIQ5yhT2cVl~rOQJsAt9f6VhBei~3f9bX1-Qr8L6yqTSulS8LhAxqlBgKE5aMhSL6So6bxKN5P29bLSW2G3I7rvoEAog8cgSKh5u3g9LN9xd9ugNq2z9rZ~8ltNk4RhegOD9WTRyDR04jhaXhRW9xpKUz6A__" className="w-[90%] h-[272px] mt-5" alt='img' />
            <span className="text-lg text-[27px] w-[90%] font-[500] mt-3">Integrate your data sources (internal & external) on avijo HPP to gain insights on your production and stimulate your order process to better understand your production capacity.</span>
            <div className="flex flex-col md:flex-row items-start justify-between mt-5 w-[90%] ml-10">
                <div className="flex-grow">
                    <h1 className="text-2xl text-black ml-4">Shipment Management</h1>
                    <div className="flex flex-row items-start mt-2">
                        <img src={require('../Assets/image/arrow.png')} className="w-[9px] h-[13px] mr-2 mt-1" />
                        <span className="text-black text-sm font-[400]">Intelligent Cargo Tracking</span>
                    </div>
                    <div className="flex flex-row items-start mt-2">
                        <img src={require('../Assets/image/arrow.png')} className="w-[9px] h-[13px] mr-2 mt-1" />
                        <span className="text-black text-sm font-[400]">Real-time ETA predictions</span>
                    </div>
                </div>
                <div className="flex-grow">
                    <h1 className="text-2xl text-black ml-4">Inventory Management</h1>
                    <div className="flex flex-row items-start mt-2">
                        <img src={require('../Assets/image/arrow.png')} className="w-[9px] h-[13px] mr-2 mt-1" />
                        <span className="text-black text-sm font-[400]"> Demand/Forecast Planning</span>
                    </div>
                    <div className="flex flex-row items-start mt-2">
                        <img src={require('../Assets/image/arrow.png')} className="w-[9px] h-[13px] mr-2 mt-1" />
                        <span className="text-black text-sm font-[400]">Centralized Inventory Monitoring</span>
                    </div>
                    <div className="flex flex-row items-start mt-2">
                        <img src={require('../Assets/image/arrow.png')} className="w-[9px] h-[13px] mr-2 mt-1" />
                        <span className="text-black text-sm font-[400]"> Inventory Simulation</span>
                    </div>
                    <button className="bg-[#0097DB] p-2 w-1/8 rounded-lg mt-4 border-1 border-[#0097DB] mr-4">
                              
                              <a href="https://hpp.avijo.in/"
                                 target="_blank"
                                 rel="noopener noreferrer"
                                 className="flex items-center gap-2 text-lg text-white no-underline">
                             <span className="text-sm text-white">Try it</span>
                            <img
                                  src={require("../Assets/image/diagonal_arrowWhite.png")}
                                 className="h-[14px] w-[18px]"
                                 alt="arrow"/></a>
                 
                             </button>
                </div>
                <div className="flex-grow">
                    <h1 className="text-2xl text-black ml-4">Order, Delivery & Invoice Management</h1>
                    <div className="flex flex-row items-start mt-2">
                        <img src={require('../Assets/image/arrow.png')} className="w-[9px] h-[13px] mr-2 mt-1" />
                        <span className="text-black text-sm font-[400]">Order & Delivery Process Management</span>
                    </div>
                    <div className="flex flex-row items-start mt-2">
                        <img src={require('../Assets/image/arrow.png')} className="w-[9px] h-[13px] mr-2 mt-1" />
                        <span className="text-black text-sm font-[400]"> Supplier Management</span>
                    </div>
                    <div className="flex flex-row items-start mt-2">
                        <img src={require('../Assets/image/arrow.png')} className="w-[9px] h-[13px] mr-2 mt-1" />
                        <span className="text-black text-sm font-[400]">SKU/Parts Management & Visualization</span>
                    </div>
                    <div className="flex flex-row items-start mt-2">
                        <img src={require('../Assets/image/arrow.png')} className="w-[9px] h-[13px] mr-2 mt-1" />
                        <span className="text-black text-sm font-[400]"> Automated Invoice Generation</span>
                    </div>
                    <div className="flex flex-row items-start mt-2">
                        <img src={require('../Assets/image/arrow.png')} className="w-[9px] h-[13px] mr-2 mt-1" />
                        <span className="text-black text-sm font-[400]">Online Communication</span>
                    </div>
                    <button className="bg-[#0097DB] p-2 w-40 rounded-lg mt-4">
                                <a href="https://hpp.avijo.in/"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="flex items-center gap-2 text-lg text-white no-underline">
                                    <span className="text-sm text-white"> Register for Demo</span>
                                    <img
                            src={require("../Assets/image/diagonal_arrowWhite.png")}
                            className="h-[14px] w-[18px]"
                            alt="arrow"/></a>
                                </button>
                </div>
            </div>
        </section>
    )
}

const Section2 = () => {
    return (
        <div className="flex flex-col w-[100%] items-center border-1 pb-5">
            <h2 className="self-center text-center w-[60%] pt-5 text-4xl">Simplify, Integrate, Optimise.</h2>
            <div className="flex flex-row items-center justify-center mt-2 w-[90%]" >
                <span className="text-black font-semibold text-[20px]">Unlock the benefits of an interconnected and streamlined supply chain with avijo HPP!</span>
            </div>
            <div className="flex flex-col md:flex-row items-start justify-between mt-5 w-[90%] ml-10">
                <div className="flex flex-col w-full md:w-1/2">
                    <h1 className="text-2xl text-black">Distribution Centre</h1>
                    <span className="text-black text-sm font-[400] pr-2">Gain clear visualisation & effectively manage all pending orders, billings and shipment statuses, with real-time data updates, on a single platform.</span>
                    <span className="text-black text-sm font-[400]">Real-Time Incoming Cargo Updates</span>
                    <div className="flex flex-row items-start mt-2">
                        <img src={require('../Assets/image/arrow2.png')} className="w-[20px] h-[20px] mr-2" />
                        <span className="text-black text-sm font-[400]">Intelligent Cargo Tracking</span>
                    </div>
                </div>
                <div className="flex flex-col w-full md:w-1/2">
                    <h1 className="text-2xl text-black">Business Unit</h1>
                    <span className="text-black text-sm font-[400] pr-2">Enhance the interoperability of your systems and stay in sync with real-time integrated updates.</span>
                    <span className="text-black text-sm font-[400]">Manage internal resources & operations</span>
                    <div className="flex flex-row items-start mt-2">
                        <img src={require('../Assets/image/arrow2.png')} className="w-[20px] h-[20px] mr-2" />
                        <span className="text-black text-sm font-[400]">Enterprise Resource Systems</span>
                    </div>
                </div>
            </div>
            <div className="flex flex-col md:flex-row items-start justify-between mt-5 w-[90%] ml-10">
                <div className="flex flex-col w-full md:w-1/2">
                    <h1 className="text-2xl text-black">Customer</h1>
                    <span className="text-black text-sm font-[400] pr-2">Mitigate communication breakdown and save the hassle of multi-logins & manual information input by collaborating & integrating with all your suppliers on a single platform</span>
                    <span className="text-black text-sm font-[400]">Real-Time Incoming Cargo Updates</span>
                    <div className="flex flex-row items-start mt-2">
                        <img src={require('../Assets/image/arrow2.png')} className="w-[20px] h-[20px] mr-2" />
                        <span className="text-black text-sm font-[400]">Customer Ordering system</span>
                    </div>
                </div>
                <div className="flex flex-col w-full md:w-1/2">
                    <h1 className="text-2xl text-black">Supplier</h1>
                    <span className="text-black text-sm font-[400] pr-2">Gain data-driven insight & calculate optimal order points to reduce safety inventory required, allowing for better cashflow.</span>
                    <span className="text-black text-sm font-[400]">Monitor Balance Orders, Shipment Status & Billing Details</span>
                    <div className="flex flex-row items-start mt-2">
                        <img src={require('../Assets/image/arrow2.png')} className="w-[20px] h-[20px] mr-2" />
                        <span className="text-black text-sm font-[400]">Supplier Ordering System</span>
                    </div>
                </div>
            </div>
            <button className="bg-[#0097DB] p-2 w-40 rounded-lg mt-4">
                                <a href="https://hpp.avijo.in/"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="flex items-center gap-2 text-lg text-white no-underline">
                                    <span className="text-sm text-white"> Register for Demo</span>
                                    <img
                            src={require("../Assets/image/diagonal_arrowWhite.png")}
                            className="h-[14px] w-[18px]"
                            alt="arrow"/></a>
                                </button>
        </div>
    )
}


export default HppHome;